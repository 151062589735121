import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GoogleMap, Marker, Polyline } from '@capacitor/google-maps';
import { environment } from 'environment';

@Component({
  selector: 'app-relatorio-google-maps',
  templateUrl: './relatorio-google-maps.component.html',
  styleUrls: ['./relatorio-google-maps.component.scss'],
})
export class RelatorioGoogleMapsComponent implements OnInit {
  @ViewChild('map') mapRef: ElementRef;
  @ViewChild('header', { static: false }) header: ElementRef;

  map: GoogleMap;
  markers: Marker[] = [];

  isModalOpen = false;
  selectedMarker: any = null;

  posicoes;
  idVeiculo;
  itemVeiculo;
  veioViewveiculo;
  vehiclePositions;
  cssIgnicao
  rotaNoMapa
  mainbg

  constructor() {
    this.mainbg = localStorage.getItem('cor');
    this.posicoes = history.state.itens;
    this.vehiclePositions = this.posicoes.reverse();
    this.idVeiculo = history.state.idVeiculo;
    this.itemVeiculo = this.posicoes[0];
    this.veioViewveiculo = history.state.veioViewveiculo;
    this.rotaNoMapa =  history.state.rotaNoMapa

    this.itemVeiculo.speed === 0 ? this.cssIgnicao = 'cardDesligado' : this.cssIgnicao = 'cardLigado';
  }

  ngOnInit(): void {}

  ionViewDidEnter() {
    this.mainbg = localStorage.getItem('cor');
    this.createMap();
  }

  closePage() {
    window.history.back();
  }

  async createMap() {
    // coordinates MUST be number types...
    const latitude = parseFloat(this.itemVeiculo.latitudeDecimalDegrees);
    const longitude = parseFloat(this.itemVeiculo.longitudeDecimalDegrees);

    this.map = await GoogleMap.create({
      id: 'map',
      element: this.mapRef.nativeElement,
      apiKey: environment.googleApiKey,

      config: {
        center: {
          lat: latitude,
          lng: longitude,
        },
        zoom: 16,
        disableDefaultUI: true,
      },
    });

    this.addMultipleMarkers(this.vehiclePositions);
    this.addPolylines(this.vehiclePositions);
  }

  async addMultipleMarkers(vehiclePositions: any[]) {
    const markers: Marker[] = vehiclePositions.map((position, index) => {
      const ignitionStatus = position.ignicao === '1' ? 'Ligada' : 'Desligada';
      let iconUrl = '../../assets/circle-pin.png';
      let title = `Percurso - ${position.dateFormated}`;
      let iconSize = {
        width: 12,
        height: 12,
      };

      let iconAnchor = {
        x: 6,
        y: 6,
      };

      if (index === 0) {
        iconUrl = '../../assets/location.png';
        title = `Início Percurso - ${position.dateFormated}`;
        iconSize = {
          width: 30,
          height: 30,
        };
        iconAnchor = {
          x: 10,
          y: 30,
        };
      }

      if (index === vehiclePositions.length - 1 && vehiclePositions.length > 2) {
        iconUrl = '../../assets/bandeira-de-corrida.png';
        title = `Fim Percurso - ${position.dateFormated}`;
        iconSize = {
          width: 30,
          height: 30,
        };
        iconAnchor = {
          x: 10,
          y: 30,
        };
      }

      console.log(
        `Ignição: ${ignitionStatus}\nVelocidade: ${position.speed} Km/h`,
      );

      return {
        coordinate: {
          lat: parseFloat(position.latitudeDecimalDegrees),
          lng: parseFloat(position.longitudeDecimalDegrees),
        },
        title: title,
        snippet: `Ignição: ${ignitionStatus}\nVelocidade: ${position.speed} Km/h`,
        iconUrl: iconUrl,
        iconSize: iconSize,
        iconAnchor: iconAnchor,
      };
    });

    await this.map.addMarkers(markers);

    //maybe we can use modal to show marker info??
    this.map.setOnMarkerClickListener(async (marker) => {
      const matchingMarketInfo = this.findMarkerInfo(marker)
      this.setCardInfo(matchingMarketInfo)
    });
  }

  async addPolylines(vehiclePositions: any[]) {
    const path = vehiclePositions.map((position) => ({
      lat: parseFloat(position.latitudeDecimalDegrees),
      lng: parseFloat(position.longitudeDecimalDegrees),
    }));

    await this.map.addPolylines([
      {
        path: path,
        strokeColor: '#66959d',
        strokeWeight: 10,
      },
    ]);
  }

  findMarkerInfo(marker) {
    // Find the clicked marker's data
    const clickedMarker = this.vehiclePositions.find(
      (position) =>
        parseFloat(position.latitudeDecimalDegrees) === marker.latitude &&
        parseFloat(position.longitudeDecimalDegrees) === marker.longitude
    );

    return clickedMarker
  }

  setCardInfo(clickedMarker) {
    this.cssIgnicao = clickedMarker.speed === '0' ? 'cardDesligado' :'cardLigado';
    this.itemVeiculo = clickedMarker
  }
}
