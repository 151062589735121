import { Component } from '@angular/core';
import { UsuarioService } from 'src/providers/usuario-service';
import { UtilService } from 'src/providers/util-service';
import { AlertController } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { setCssColors } from 'src/app/colors';
import { environment } from '../../environment';
import { register } from 'swiper/element/bundle';
import OneSignal from 'onesignal-cordova-plugin';
import { OneSignal as One } from 'onesignal-ngx';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';


const versaoApp = environment.versaoAtual;

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  aparelho: string;
  plataforma: string;
  imei: string;

  constructor(
    public util: UtilService,
    private router: Router,
    public device: Device,
    public usuario: UsuarioService,
    public user: UsuarioService,
    private alertCtrl: AlertController,
    private oneSignalForWeb: One,
  ) {
    // * some requests may need device info to work properly ...
    this.fetchDeviceInfo()
      .then(() => console.info('Success fetching device info!'))
      .catch((res) => console.warn('Error getting device info:', res));

    this.askPushPermission();

    setInterval(() => this.updateUserInfo(), 15000);

    App.addListener('backButton', ({ canGoBack }) => {
      const currentUrl = this.router.url;

      if (!canGoBack || currentUrl === '/dashboard' ) {
        App.exitApp();
      } else {
        window.history.back();
      }
    });
  }

  async fetchDeviceInfo() {
    this.imei = await this.util.getDeviceID();
    this.plataforma = await this.util.getDevicePlatform();
    this.aparelho = await this.util.getDeviceModel();

    console.log(this.imei);
    console.log(this.plataforma);
    console.log(this.aparelho);
  }

  // * precisamos configurar o SDK também pra ios
  // * https://documentation.onesignal.com/docs/ionic-capacitor-cordova-sdk-setup

  oneSignalInit() {
    if (!localStorage.getItem('chave_push')) {
      const chaveAleatoria = this.gerarChaveAleatoria(20);
      localStorage.setItem('chave_push', chaveAleatoria);
    }

    const externalId = localStorage.getItem('chave_push');
    localStorage.setItem('pushNotifications', 'true');

    try {
      OneSignal.Debug.setLogLevel(6);

      OneSignal.initialize(environment.chaveOneSignal);

      OneSignal.login(externalId);

      OneSignal.Notifications.requestPermission(true)
        .then((success: Boolean) => {
          console.log('Notification permission granted ' + success);
          this.updateOurAPIPushInfo();
        })
        .catch((error) => {
          localStorage.setItem('pushNotifications', 'false');
          console.error(error);
          this.util.showToast('Não foi possível configurar as notificações.');
        });
    } catch (error) {
      console.log(error);
      this.util.showToast('Não foi possível configurar as notificações.');
    }
  }

  // TODO: mesmo com o usuário fazendo logoff ele pode continuar recebendo push
  // TODO: mesmo com o usuário trocando de conta ele pode receber push da conta que logou primeiro
  // * resolver assim que possível ?

  async oneSignalWeb() {
    const isOneSignalInstalled = await this.isOneSignalInstalled();

    // se necessário debugar, use isso:
    this.oneSignalForWeb.Debug.setLogLevel('trace');

    if (isOneSignalInstalled) {
      console.log('OneSignal já está instalado.');

      const externalId = await this.getPreviousExternalId();

      if (externalId) {
        this.oneSignalForWeb.Slidedown.promptPush({force: true});
        localStorage.setItem('pushNotifications', 'true');
        localStorage.setItem('chave_push', externalId);
        this.setBellIconPWA();
        this.updateOurAPIPushInfo();
      } else {
        console.warn('Não foi possível pegar o externalId anterior');
      }

      return;
    }

    this.oneSignalForWeb
      .init({
        appId: environment.chaveOneSignal,
        serviceWorkerParam: { scope: '/' },
        serviceWorkerPath: '/OneSignalSDKWorker.js',
        allowLocalhostAsSecureOrigin: true
      })
      .then(() => {
        this.oneSignalWebConfiguration();
      })
      .catch((erro) => {
        localStorage.setItem('pushNotifications', 'false');
        console.error('Erro ao inicializar OneSignal:', erro);
        this.util.showToast('Erro ao configurar suas notificações de push!');
      });
  }

  private setBellIconPWA() {
    // * não sei como isso retorna false as vezes
    // * sendo que, o usuário ESTÁ recebendo push
    // * zero sentido da onesignal ...
    const permission = this.oneSignalForWeb.Notifications.permission;
    // console.log('push permission:', permission);

    if (permission) {
      localStorage.setItem('pushNotifications', 'true');
    } else {
      const confirmPermission = this.checkNotificationsPermission();

      if (confirmPermission) {
        localStorage.setItem('pushNotifications', 'true');
      } else {
        localStorage.setItem('pushNotifications', 'false');
      }
    }
  }

  checkNotificationsPermission() {
    return Notification.permission === 'granted';
  }

  async getPreviousExternalId(): Promise<string | null> {
    const dbName = 'ONE_SIGNAL_SDK_DB';
    const storeName = 'Options';

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);

      request.onsuccess = (event) => {
        const db = request.result;

        // Inicia uma transação de leitura na store 'Options'
        // a store 'Options' é uma tabela que tem o externalId
        const transaction = db.transaction([storeName], 'readonly');
        const store = transaction.objectStore(storeName);

        // Realiza a leitura da chave 'previousExternalId'
        const getRequest = store.get('previousExternalId');

        getRequest.onsuccess = () => {
          const previousExternalId = getRequest.result;
          console.log('previousExternalId:', previousExternalId);

          resolve(previousExternalId.value);
        };

        getRequest.onerror = () => {
          console.error('Erro ao acessar previousExternalId no IndexedDB.');
          reject(null);
        };
      };

      request.onerror = (event) => {
        console.error('Erro ao abrir o IndexedDB da OneSignal:', event);
        reject(null);
      };
    });
  }

  oneSignalWebConfiguration() {
    if (!localStorage.getItem('chave_push')) {
      const chaveAleatoria = this.gerarChaveAleatoria(20);
      localStorage.setItem('chave_push', chaveAleatoria);
    }

    const setPushInfo = (event) => {
      if (event.current.token) {
        localStorage.setItem('pushNotifications', 'true');
      } else {
        localStorage.setItem('pushNotifications', 'false');
      }
    };

    this.oneSignalForWeb.Slidedown.promptPush({ force: true });

    this.oneSignalForWeb.User.PushSubscription.addEventListener('change', setPushInfo);

    this.oneSignalForWeb.login(localStorage.getItem('chave_push'));

    this.setBellIconPWA();

    this.updateOurAPIPushInfo();
  }

  updateOurAPIPushInfo() {
    const request = {
      token: localStorage.getItem('hash'),
      player: localStorage.getItem('chave_push'),
      imei: this.imei,
      aparelho: this.aparelho,
      plataforma: this.plataforma,
    };

    this.usuario
      .atualizarPush(request)
      .then((res) => console.log('Success', res))
      .catch((error) => {
        localStorage.setItem('pushNotifications', 'false');
        console.error('Erro na API Hapolo', error);
        this.util.showToast('Erro ao configurar suas notificações de push!');
      });
  }

  async isOneSignalInstalled() {
    const registrations = await navigator.serviceWorker.getRegistrations();
    return registrations.some(
      (registration) =>
        registration.active &&
        registration.active.scriptURL.includes('OneSignalSDKWorker.js'),
    );
  }

  // * refatorar isso?
  updateUserInfo() {
    console.info('Updating user info ...');
    let request = { token: localStorage.getItem('hash') };
    if (localStorage.getItem('url_padrao') != null) {
      if (localStorage.getItem('url_padrao')) {
        this.user.getMenu(request).then((response) => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.success === true) {
            localStorage.setItem(
              'exibe_parceiros',
              res.dados_empresa.exibe_parceiros,
            );
            localStorage.setItem(
              'bloqueio_msg',
              res.dados_empresa.mensagem_bloqueio,
            );
            localStorage.setItem(
              'dados_cliente',
              JSON.stringify(res.dados_cliente),
            );
            localStorage.setItem(
              'situacao_sga',
              res.dados_cliente.situacao_sga,
            );
            localStorage.setItem('tem_rotas', res.dados_cliente.tem_rotas);
            localStorage.setItem(
              'dados_empresa',
              JSON.stringify(res.dados_empresa),
            );
            localStorage.setItem(
              'dns_empresa',
              res.dados_empresa.dados_login.dns,
            );
            localStorage.setItem('color', '#b134eb');
            let cor = '#' + res.dados_empresa.cor_app;
            localStorage.setItem('atendimento', res.dados_empresa.atendimento);
            localStorage.setItem(
              'whatsapp_telefone',
              res.dados_empresa.whatsapp_telefone,
            );
            localStorage.setItem(
              'whatsapp_mensagem',
              res.dados_empresa.whatsapp_mensagem,
            );
            localStorage.setItem(
              'link_personalizado',
              res.dados_empresa.link_personalizado,
            );
            localStorage.setItem(
              'mensagem_personalizado',
              res.dados_empresa.mensagem_personalizado,
            );
            localStorage.setItem('dashapp', res.dados_empresa.dashapp);
            localStorage.setItem('cor', cor);
            localStorage.setItem('id_admin', res.dados_cliente.id_admin);
            this.setCor();
            localStorage.setItem('logomarca', res.dados_empresa.logo2);
            localStorage.setItem('favicon', res.dados_empresa.logo3);
            localStorage.setItem(
              'exibir_contratos_app',
              res.dados_empresa.exibir_contratos_app,
            );
            localStorage.setItem(
              'exibe_arquivo',
              res.dados_empresa.exibe_arquivo,
            );
            localStorage.setItem(
              'exibe_contatos',
              res.dados_empresa.exibe_contatos,
            );

            localStorage.setItem('exibe_chat', res.dados_empresa.exibe_chat);
            localStorage.setItem(
              'exibe_indique_um_amigo',
              res.dados_empresa.exibe_indique_um_amigo,
            );
            localStorage.setItem(
              'exibe_sobre_empresa',
              res.dados_empresa.exibe_sobre_empresa,
            );
            if (
              res.dados_cliente.tipo === 'A1' ||
              res.dados_cliente.tipo === 'A2' ||
              res.dados_cliente.tipo === 'A3'
            ) {
              localStorage.setItem('exibe_parametros', '1');
            } else {
              localStorage.setItem(
                'exibe_parametros',
                res.dados_cliente.exibe_parametros,
              );
            }
            localStorage.setItem(
              'numero_atendimento',
              res.dados_empresa.numero_atendimento,
            );
            localStorage.setItem(
              'exibe_faturamento',
              res.dados_empresa.exibe_faturamento,
            );
            localStorage.setItem(
              'exibir_termo_app',
              res.dados_empresa.exibir_termo_app,
            );
            localStorage.setItem(
              'menuinicial_app',
              res.dados_empresa.menuinicial_app,
            );
            localStorage.setItem(
              'permitir_obs_app',
              res.dados_empresa.permitir_obs_app,
            );
            localStorage.setItem(
              'exibir_saude',
              res.dados_empresa.exibir_saude,
            );
            localStorage.setItem(
              'exibir_eventos',
              res.dados_empresa.exibir_eventos,
            );
            localStorage.setItem(
              'mostrar_boletos',
              res.dados_empresa.mostrar_boletos,
            );
            localStorage.setItem(
              'selfie_ass_contrato',
              res.dados_empresa.selfie_ass_contrato,
            );
            localStorage.setItem(
              'termos_pre_acesso',
              res.dados_empresa.termos_pre_acesso,
            );
            localStorage.setItem(
              'codigo_associado',
              res.dados_cliente.codigos_associado,
            );
            localStorage.setItem(
              'sistema_pontos',
              res.dados_empresa.sistema_de_pontos ? '1' : '0',
            );
            localStorage.setItem(
              'bg_cartao_v2_frente',
              res.dados_empresa.bg_cartao_v2_frente,
            );
            localStorage.setItem(
              'bg_cartao_v2_verso',
              res.dados_empresa.bg_cartao_v2_verso,
            );
            localStorage.setItem(
              'cartao_associado_v2',
              res.dados_empresa.cartao_associado_v2,
            );

            localStorage.setItem(
              'mostrar_clube_gas',
              res.dados_empresa.mostrar_clube_gas,
            );
            localStorage.setItem(
              'url_clube_gas',
              res.dados_empresa.url_clube_gas,
            );
            localStorage.setItem(
              'mostrar_odonto_clube',
              res.dados_empresa.mostrar_odonto_clube,
            );
            localStorage.setItem(
              'url_odonto_clube',
              res.dados_empresa.url_odonto_clube,
            );
            localStorage.setItem(
              'mostrar_doc24',
              res.dados_empresa.mostrar_doc24,
            );
            localStorage.setItem('url_doc24', res.dados_empresa.url_doc24);
            localStorage.setItem(
              'url_eventos_sga',
              res.dados_empresa.url_eventos_sga,
            );
            localStorage.setItem(
              'mostrar_posto_bufon',
              res.dados_empresa.mostrar_posto_bufon,
            );
            localStorage.setItem(
              'url_posto_bufon',
              res.dados_empresa.url_posto_bufon,
            );
            localStorage.setItem(
              'nome_topo_app',
              res.dados_empresa.nome_topo_app,
            );

            localStorage.setItem('app_atualizado', 'true');
            localStorage.setItem('app_url', '');
            console.log(res.dados_empresa.dados_login);
            var lg = res.dados_empresa.dados_login;
            var versao = '';
            var url = '';
            if (
              this.plataforma === 'Android' ||
              this.plataforma === 'android'
            ) {
              versao = lg ? lg['versao_android'] : '';
              url = lg ? lg['url_android'] : '';
            } else {
              versao = lg ? lg['versao_ios'] : '';
              url = lg ? lg['url_ios'] : '';
            }
            if (versao) {
              if (versao.includes('.')) {
                console.log(versao);
                var teste1 = versaoApp.split('.');
                var teste2 = versao.split('.');
                if (versao !== versaoApp) {
                  if (teste1.length > 2) {
                    var a = parseInt(teste1[0]);
                    var b = parseInt(teste1[1]);
                    var c = parseInt(teste1[2]);

                    var a2 = parseInt(teste2[0]);
                    var b2 = parseInt(teste2[1]);
                    var c2 = parseInt(teste2[2]);

                    var atualizado = false;

                    if (a > a2) {
                      atualizado = true;
                    } else {
                      if (b > b2) {
                        atualizado = true;
                      } else {
                        if (c > c2) {
                          atualizado = true;
                        }
                      }
                    }
                    console.log('seta');
                    console.log(atualizado ? 'updated' : 'not updated');
                    localStorage.setItem('app_atualizado', '' + atualizado);
                    localStorage.setItem('app_url', url);
                    localStorage.setItem('app_versao', versao);
                  }
                }
              }
            }
          }
        });
      }
    }
  }

  setCor() {
    const mainbg = localStorage.getItem('cor') || '#326d8c';

    setCssColors(mainbg);
  }

  gerarChaveAleatoria(tamanho: number) {
    const caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let chave = '';
    for (let i = 0; i < tamanho; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      chave += caracteres.charAt(indice);
    }
    return chave;
  }

  async avisoPushNegativo() {
    const alert = await this.alertCtrl.create({
      header:
        'Suas notificações push estão desativadas, por favor abra as configurações do aplicativo, e ative-as.',
      buttons: [
        {
          text: 'OK',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  private async oneSignalPushOption() {
    const alert = await this.alertCtrl.create({
      header: 'Deseja receber notificações de rastreamento?',
      buttons: [
        {
          text: 'Sim',
          handler: () => {
            this.oneSignalForWeb.User.PushSubscription.optIn();
          },
        },
        {
          text: 'Não',
          handler: () => {
            this.oneSignalForWeb.User.PushSubscription.optOut();
          },
        },
      ],
    });

    await alert.present();
  }

  private async promptForNativeBuild() {
    const alert = await this.alertCtrl.create({
      header: 'Gostaria de receber notificações push?',
      buttons: [
        {
          text: 'SIM',
          handler: () => {
            this.oneSignalInit();
          },
        },
        {
          text: 'Talvez mais tarde',
          handler: () => {
            localStorage.setItem('pushNotifications', 'false');
          },
        },
      ],
    });

    //show modal in only first login
    if (
      localStorage.getItem('hash') &&
      !localStorage.getItem('pushNotifications')
    ) {
      await alert.present();
    }
  }

  async askPushPermission() {
    if (localStorage.getItem('pushNotifications') === 'true') return;

    if (localStorage.getItem('pushNotifications') === 'true') {
      this.util.showToast('O aplicativo está recebendo push.')
      return
    }

    // ? case PWA/Web
    if (this.plataforma === 'web') this.oneSignalWeb();

    // ? case android/ios
    if (this.plataforma !== 'web') this.promptForNativeBuild();
  }

  async clearAllData() {
    // Limpar Local Storage
    localStorage.clear();

    // Limpar Session Storage
    sessionStorage.clear();

    // Limpar Cookies
    document.cookie.split(';').forEach(function (cookie) {
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    });

    // Limpar IndexedDB
    if (window.indexedDB) {
      indexedDB
        .databases()
        .then((databases) => {
          databases.forEach((db) => {
            indexedDB.deleteDatabase(db.name);
          });
        })
        .catch((error) => {
          console.error('Erro ao apagar IndexedDB:', error);
        });
    }

    // Limpar Caches (Cache API) associado ao Service Worker
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName)),
      );
      console.log('Caches limpos com sucesso');
    }

    // Desativar qualquer Service Worker ativo
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        })
        .catch((error) => {
          console.error('Erro ao interagir com Service Workers:', error);
        });
    }

    console.log('Todos os dados foram apagados');
  }
}
